import React, { useEffect, useState, Fragment, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardHeader,
  Table,
} from "reactstrap";
import MaterialReactTable from "material-react-table";
import { format } from "date-fns";
import { DocumentAttach } from ".";
import { exportToExcel } from "react-json-to-excel";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
const DocTableList = ({ tableData, objShowBtnAction, showPartner, titlepage }) => {
  const navigate = useNavigate();
  const [DocumentName, setDocumentName] = useState("");
  const [modal, setModal] = useState(false);
  const [modalFile, setmodalFile] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [sumRows, setsumRows] = useState(0);
  const [objConfrim, setobjConfrim] = useState({
    id: "",
    confrim: "Yes",
    docLogType: "",
    description: "",
  });
  const tableInstanceRef = useRef(null);
  const scollToRef = useRef();
  const FormatDate = (val) => {
    var date = new Date(val);
    var formattedDate = format(date, "MMMM d, yyyy");
    return formattedDate;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setsumRows(tableData.length);
        scollToRef.current.scrollIntoView();
      } catch (err) {}
    };

    fetchData();
  }, [tableData]);
  const toggle = () => setModal(!modal);
  const handleExportExcel = () => {
    const customHeadings = tableData.map((item) => ({
      DocTitle: item.docTitle,
      DocType: item.fillingMainStructure1Description,
      ProjectPlant:
        item.projectsDescription != null ? (
          <span>{item.projectsDescription}</span>
        ) : item.plantsDescription != null ? (
          <span>{item.plantsDescription}</span>
        ) : null,
      Filling: item.fillingMainStructure2Description,
      SystemCode: item.systemCode,
      DocDate: FormatDate(item.dateAdd),
      ProducerName: item.producerName,
      ProducerCode: item.producerDocumentCode,
      Ver: item.versionID,
      Partner: item.letterPartner,
      Position: item.position,
      Priority: item.priority,
      Security: item.security,
      Creator: item.creator,
      CreatorCode: item.userPersonalCode,
    }));
    exportToExcel(customHeadings, titlepage);
  };
  const handleExportPdf = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
    });
    let RowNumber = 1;
    const customRows = tableData.map((item) => [
      RowNumber++,
      item.docTitle,
      item.fillingMainStructure1Description,
      item.projectsDescription != null ? (
        <span>{item.projectsDescription}</span>
      ) : item.plantsDescription != null ? (
        <span>{item.plantsDescription}</span>
      ) : null,
      item.fillingMainStructure2Description,
      item.systemCode,
      FormatDate(item.dateAdd),
      item.producerName,
      item.producerDocumentCode,
      item.versionID,
      item.letterPartner,
      item.position,
      item.priority,
      item.security,
      item.creator,
      item.userPersonalCode,
    ]);
    autoTable(doc, {
      head: [
        [
          "Row Number",
          "DocTitle",
          "DocType",
          "ProjectPlant",
          "Filling",
          "SystemCode",
          "DocDate",
          "ProducerName",
          "ProducerCode",
          "Ver",
          "Partner",
          "Position",
          "Priority",
          "Security",
          "Creator",
          "CreatorCode",
        ],
      ],
      body: customRows,
    });
    doc.text(titlepage, 14, 10);
    doc.save(titlepage + ".pdf");
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "docTitle",
        header: "Doc.Title",
      },
      {
        accessorKey: "docDescription",
        header: "Doc.Description",
      },
      {
        accessorKey: "fillingMainStructure1Description",
        header: "Doc.Type",
      },
      {
        header: "Project/Plant",
        Cell: ({ cell }) =>
          cell.row.original.projectsDescription != null ? (
            <span>{cell.row.original.projectsDescription}</span>
          ) : cell.row.original.plantsDescription != null ? (
            <span>{cell.row.original.plantsDescription}</span>
          ) : null,
      },
      {
        accessorKey: "fillingMainStructure2Description",
        header: "Filling",
      },
      {
        header: "System Code",
        accessorKey: "systemCode",

        Cell: ({ cell }) =>
          cell.row.original.confrim == "Yes" ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID + " " + cell.row.original.versionID}</span>
          ) : cell.row.original.serialID > 0 ? (
            <span>{cell.row.original.systemCode + " " + cell.row.original.serialID}</span>
          ) : (
            <span>{cell.row.original.systemCode}</span>
          ),
      },
      {
        accessorKey: "dateAdd",
        header: "Doc.Date",
        Cell: ({ cell }) => <span>{FormatDate(cell.row.original.dateAdd)}</span>,
      },
      {
        accessorKey: "producerName",
        header: "Producer Name",
      },

      {
        accessorKey: "producerDocumentCode",
        header: "Producer Code",
      },
      {
        accessorKey: "versionID",
        header: "Ver",
      },
      {
        accessorKey: "letterPartner",
        header: "Partner",

        Cell: ({ cell }) => <span>{cell.row.original.letterPartner}</span>,
      },
      {
        accessorKey: "RecivedBy",
        header: "Recieved / Dispatched by",

        Cell: ({ cell }) => <span>{cell.row.original.recivedBy}</span>,
      },

      {
        accessorKey: "position",
        header: "Position",
        Cell: ({ cell }) =>
          cell.row.original.position == "Active" ? <span className="txt-success">Active</span> : <span className="txt-danger">Inactive</span>,
      },
      {
        accessorKey: "priority",
        header: "Priority",
      },
      {
        accessorKey: "security",
        header: "Security",
      },
      {
        accessorKey: "creator",
        header: "Creator",
        Cell: ({ cell }) => (
          <span>
            {cell.row.original.userName} {cell.row.original.userFamily}
          </span>
        ),
      },
      {
        accessorKey: "userPersonalCode",
        header: "Creator Code",
      },
      {
        accessorKey: "archiveNo",
        header: "Archive No",
      },
      {
        header: "File",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button
                className="btn btn-info mr-5 btn-xs"
                onClick={() => {
                  setDocumentName(cell.row.original.docTitle);
                  setobjConfrim({
                    ...objConfrim,
                    id: cell.row.id,
                    confrim: cell.row.original.confrim,
                  });
                  setmodalFile(true);
                }}
              >
                F
              </Button>
            </span>
          </>
        ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableColumnDragging: false,
        enableExpanding: false,
        enableColumnActions: false,
        enableHiding: false,
        Cell: ({ cell }) => (
          <>
            <span>
              <Button className="btn btn-info mr-5 btn-xs" onClick={() => navigate(`${process.env.PUBLIC_URL}/admin/Document/edit/${cell.row.id}`)}>
                D
              </Button>
            </span>
          </>
        ),
      },
    ],
    []
  );
  return (
    <Fragment>
      <Form className="theme-form">
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              {tableData != "" && tableData != null ? (
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <div className="lbl-sum-Table">
                          <Row>
                            <Col md="6">
                              <Label className="form-label">{"Sum : "}</Label> <Label className="form-label">{sumRows}</Label>
                            </Col>
                            <Col md="6 text-right" className="export-iconList">
                              <i
                                onClick={() => {
                                  handleExportExcel();
                                }}
                                className="fa fa-file-excel-o"
                              ></i>
                              <i
                                onClick={() => {
                                  handleExportPdf();
                                }}
                                className="fa fa-file-pdf-o"
                              ></i>
                            </Col>
                          </Row>
                        </div>
                        <div ref={scollToRef}>
                          <MaterialReactTable
                            enableStickyHeader
                            muiTableContainerProps={{
                              sx: { maxHeight: "350px" },
                            }}
                            muiTablePaperProps={{
                              elevation: 0,
                              sx: {
                                color: "#fff",
                                borderRadius: "0",
                                border: "0",
                                "& tbody tr:nth-of-type(odd)": {
                                  backgroundColor: "#f5f5f5",
                                },
                                "& thead tr": {
                                  backgroundColor: "#16449D",
                                  color: "#fff",
                                },
                              },
                            }}
                            muiTableHeadCellProps={{
                              sx: (theme) => ({
                                color: "#fff",
                              }),
                            }}
                            muiTableHeadCellDragHandleProps={{
                              sx: (theme) => ({
                                color: "#fff",
                              }),
                            }}
                            muiTableHeadCellColumnActionsButtonProps={{
                              sx: (theme) => ({
                                color: "#fff",
                              }),
                            }}
                            muiTableHeadCellFilterTextFieldProps={{
                              sx: (theme) => ({
                                color: "#fff",
                              }),
                            }}
                            getRowId={(originalRow) => originalRow.id}
                            columns={columns}
                            data={tableData}
                            enableColumnOrdering
                            enableExpanding
                            enablePagination={false}
                            enableRowNumbers
                            enableSorting={true}
                            enableColumnDragging={false}
                            onRowSelectionChange={setRowSelection}
                            state={{ rowSelection }}
                            tableInstanceRef={tableInstanceRef}
                            initialState={{
                              columnVisibility: {
                                Actions: objShowBtnAction.edit,
                                letterPartner: showPartner,
                              },
                            }}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Container>
      </Form>
      <Modal isOpen={modalFile} size="lg">
        <ModalHeader>{DocumentName}</ModalHeader>
        <ModalBody>
          <DocumentAttach openfrom={"Yes"} serialID={0} disabledbtn={true} obj={objConfrim} documentID={objConfrim.id} />
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalFile(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DocTableList;
