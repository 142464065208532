import {
  SettingtSvg,
  BlogSvg,
  BonusUISvg,
  ButtonsSvg,
  CalanderSvg,
  ChartsSvg,
  ChatSvg,
  ContactSvg,
  EcommerceSvg,
  EditorsSvg,
  EmailSvg,
  FAQSvg,
  FilemanagerSvg,
  FormsSvg,
  GallarySvg,
  HeaderBookmarkSvg,
  HomeSvg,
  ContenttSvg,
  IconsSvg,
  JobsearchSvg,
  KanbanSvg,
  KnowledgebaseSvg,
  LearningSvg,
  MapsSvg,
  OthersSvg,
  ProjectSvg,
  SamplePageSvg,
  SearchResultSvg,
  SocialappSvg,
  SupportTicketSvg,
  TablesSvg,
  TaskSvg,
  TodoSvg,
  UiKitsSvg,
  UsersComponentSvg,
  WidgetsSvg,
  AdminUsersSvg,
  UserSvg,
} from "../../Data/svgIcons";

const MENUAdministrator = [
  {
    menutitle: "QuickAccess",
    code: "d1",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/admin/QuickAccess`,
        active: true,
        icon: ProjectSvg,
        title: "Quick Access",
        code: "d1",
        type: "link",
      },
    ],
  },
  {
    menutitle: "Data Managements",
    code: "a",
    Items: [
      {
        title: "Data Managements",
        icon: ProjectSvg,
        type: "sub",
        code: "a",
        badge: false,
        active: false,
        children: [
          {
            title: "Documents Management",
            type: "sub",
            code: "ab",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/admin/Document/add`,
                title: "New Document",
                type: "link",
                code: "ab11",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Document/List`,
                title: "Document List",
                type: "link",
                code: "ab12",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Document`,
                title: "Documents Reports",
                type: "link",
                code: "ab13",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Document/WaitingList`,
                title: "waiting List",
                type: "link",
                code: "ab14",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Document/NonApprovedList`,
                title: "Non Approved List",
                type: "link",
                code: "ab15",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Document/DeletedList`,
                title: "Deleted Doc. LIST",
                type: "link",
                code: "ab17",
              },
            ],
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Notyetactivated`,
            title: "Projects Management",
            type: "link",
            code: "ab21",
          },
          {
            title: "Bid , Auction Management",
            type: "sub",
            code: "ab22",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/admin/BidAuction/add`,
                title: "New Bid , Auction",
                type: "link",
                code: "ab22a",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/BidAuction`,
                title: "Report",
                type: "link",
                code: "ab22i",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/BidAuction/WaitingList`,
                title: "Waiting List",
                type: "link",
                code: "ab22g",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/BidAuction/NonApprovedList`,
                title: "Non Approved List",
                type: "link",
                code: "ab22e",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/BidAuction/DeletedList`,
                title: "Deleted List",
                type: "link",
                code: "ab22f",
              },
            ],
          },
          {
            title: "Contracts Management",
            type: "sub",
            code: "ab23",
            children: [
              {
                path: `${process.env.PUBLIC_URL}/admin/Contracts/add`,
                title: "New Contract",
                type: "link",
                code: "ab23a",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Contracts`,
                title: "Report",
                type: "link",
                code: "ab23i",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Contracts/WaitingList`,
                title: "Waiting List",
                type: "link",
                code: "ab23g",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Contracts/NonApprovedList`,
                title: "Non Approved List",
                type: "link",
                code: "ab23e",
              },
              {
                path: `${process.env.PUBLIC_URL}/admin/Contracts/DeletedList`,
                title: "Deleted List",
                type: "link",
                code: "ab23f",
              },
            ],
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Notyetactivated`,
            title: "HR Management",
            type: "link",
            code: "ab26",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Notyetactivated`,
            title: "Physical Assets Management",
            type: "link",
            code: "ab23",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Notyetactivated`,
            title: "Manitenance Management",
            type: "link",
            code: "ab24",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Notyetactivated`,
            title: "Production Management",
            type: "link",
            code: "ab25",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Users Management",
    Items: [
      {
        code: "b",
        title: "Users Management",
        icon: AdminUsersSvg,
        type: "sub",
        badge: false,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/User/add`,
            title: "New User",
            type: "link",
            code: "b1",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/User`,
            title: "Users List",
            type: "link",
            code: "b2",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/AccessLevels`,
            title: "Access Levels",
            type: "link",
            code: "b3",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Basic Data Management",
    Items: [
      {
        code: "c",
        title: "Basic Data Management",
        icon: SettingtSvg,
        type: "sub",
        badge: false,
        active: false,

        children: [
          {
            title: "Holding Organization",
            type: "sub",
            code: "c11",
            children: [
              {
                title: "Companies List",
                code: "c12",
                type: "link",
                path: `${process.env.PUBLIC_URL}/admin/Company`,
              },
              {
                title: "New Company",
                type: "link",
                code: "c13",
                path: `${process.env.PUBLIC_URL}/admin/Company/add`,
                active: false,
              },
            ],
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Department`,
            title: "Departments",
            code: "c14",
            type: "link",
          },

          {
            path: `${process.env.PUBLIC_URL}/admin/Projects`,
            title: "Projects",
            type: "link",
            code: "c15",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Plants`,
            title: "Plants",
            type: "link",
            code: "c17",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ProjectUnitCategory`,
            title: "Unit Categories",
            type: "link",
            code: "c16",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/FilingStructure`,
            title: "Filing Structure",
            code: "c18",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/ContractsType`,
            title: "Contracts Type",
            type: "link",
            code: "c19",
          },

          {
            path: `${process.env.PUBLIC_URL}/admin/JobPosition`,
            title: "Job Positions",
            type: "link",
            code: "c20",
          },

          {
            path: `${process.env.PUBLIC_URL}/admin/Nationality`,
            title: "Nationalities",
            type: "link",
            code: "c21",
          },

          {
            path: `${process.env.PUBLIC_URL}/admin/InteractingCompanies`,
            title: "Interacting Co. s",
            type: "link",
            code: "c22",
          },
          {
            title: "Technical Documents",
            code: "c23",
            type: "sub",
            children: [
              {
                title: "Phase",
                type: "link",
                code: "c24",
                path: `${process.env.PUBLIC_URL}/admin/TechnicalDocuments/Phase`,
              },
              {
                title: "Discipline",
                type: "link",
                code: "c25",
                path: `${process.env.PUBLIC_URL}/admin/TechnicalDocuments/Discipline`,
              },
              {
                title: "Doc.Type",
                type: "link",
                code: "c26",
                path: `${process.env.PUBLIC_URL}/admin/TechnicalDocuments/DocType`,
              },
            ],
          },
          {
            title: "Contractors & Vendors",
            code: "c27",
            type: "sub",
            children: [
              {
                title: "List",
                type: "link",
                code: "c28",
                path: `${process.env.PUBLIC_URL}/admin/VendorsCompanies`,
              },
              {
                title: "Discipline",
                type: "link",
                code: "c29",
                path: `${process.env.PUBLIC_URL}/admin/ContractorsVendors/Discipline`,
              },
            ],
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Correspondences/RecieverSender`,
            title: "Letters Reciever & Sender",
            type: "link",
            code: "c30",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/StaticTex`,
            title: "Static Texts",
            type: "link",
            code: "c31",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/AdviceQuestionTitle`,
            title: "Advice & Question Title",
            type: "link",
            code: "c32",
          },
          {
            title: "Legend",
            type: "sub",
            code: "c33",
            children: [
              {
                title: "Category",
                type: "link",
                code: "c33",
                path: `${process.env.PUBLIC_URL}/admin/LegendCategory`,
              },
              {
                title: "List",
                type: "link",
                code: "c33",
                path: `${process.env.PUBLIC_URL}/admin/LegendList`,
              },
              {
                title: "Add",
                type: "link",
                code: "c33",
                path: `${process.env.PUBLIC_URL}/admin/Legend/Add`,
              },
            ],
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/DataTypeAccess`,
            title: "Data Type Access",
            code: "c35",
            type: "link",
            active: false,
          },
        ],
      },
    ],
  },
  {
    menutitle: "Services Management",
    Items: [
      {
        code: "d",
        title: "Services Management",
        icon: FormsSvg,
        type: "sub",
        badge: false,
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/Dashboard`,
            title: "Dashboard",
            code: "d1",
            type: "link",
            active: false,
          },

          {
            title: "Bulletin Board",
            code: "d2",
            type: "sub",
            children: [
              {
                title: "List",
                type: "link",
                code: "d2",
                path: `${process.env.PUBLIC_URL}/admin/BulletinBoard`,
              },
              {
                title: "Add",
                type: "link",
                code: "d9",
                path: `${process.env.PUBLIC_URL}/admin/BulletinBoard/Add`,
              },
            ],
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/Messenger/Inbox`,
            title: "Messenger",
            code: "d3",
            type: "link",
            active: false,
          },

          {
            title: "Daily Plans",
            code: "d4",
            type: "sub",
            children: [
              {
                title: "List",
                type: "link",
                code: "d4",
                path: `${process.env.PUBLIC_URL}/admin/DailyPlans`,
              },
              {
                title: "Report",
                type: "link",
                code: "d4",
                path: `${process.env.PUBLIC_URL}/admin/DailyPlansReport`,
              },
              {
                title: "Add",
                type: "link",
                code: "d4",
                path: `${process.env.PUBLIC_URL}/admin/DailyPlans/Add`,
              },
            ],
          },
          {
            title: "Meeting Plans",
            code: "d5",
            type: "sub",
            children: [
              {
                title: "List",
                type: "link",
                code: "d5",
                path: `${process.env.PUBLIC_URL}/admin/MeetingPlans`,
              },
              {
                title: "Reprot",
                type: "link",
                code: "d5",
                path: `${process.env.PUBLIC_URL}/admin/MeetingPlansReport`,
              },
              {
                title: "Add",
                type: "link",
                code: "d5",
                path: `${process.env.PUBLIC_URL}/admin/MeetingPlans/Add`,
              },
            ],
          },

          {
            path: `${process.env.PUBLIC_URL}/admin/TelBook`,
            title: "Tel. Books",
            code: "d6",
            type: "sub",
            children: [
              {
                title: "Company Tel. Books",
                type: "link",
                code: "d6",
                path: `${process.env.PUBLIC_URL}/admin/TelBook/CompanyTelBook`,
              },
              {
                title: "Private Tel. Books",
                type: "link",
                code: "d6",
                path: `${process.env.PUBLIC_URL}/admin/TelBook/PrivateTelBook`,
              },
              {
                title: "Interacting Co.'s Tel. Books",
                type: "link",
                code: "d6",
                path: `${process.env.PUBLIC_URL}/admin/TelBook/Interacting`,
              },
              {
                title: "New Company Tel. Books",
                type: "link",
                code: "d10",
                path: `${process.env.PUBLIC_URL}/admin/TelBook/Add/CompanyTelBook`,
              },
              {
                title: "New Private Tel. Book",
                type: "link",
                code: "d11",
                path: `${process.env.PUBLIC_URL}/admin/TelBook/Add/PrivateTelBook`,
              },
              {
                title: "New Interacting Co.'s Tel. Book",
                type: "link",
                code: "d12",
                path: `${process.env.PUBLIC_URL}/admin/TelBook/Add/Interacting`,
              },
            ],
          },
          {
            title: "Wisdom Cooperation",
            code: "d13",
            type: "sub",
            children: [
              {
                title: "Questions",
                type: "link",
                code: "d13",
                path: `${process.env.PUBLIC_URL}/admin/Questions`,
              },
              {
                title: "Advices",
                type: "link",
                code: "d13",
                path: `${process.env.PUBLIC_URL}/admin/Advices`,
              },
            ],
          },
        ],
      },
    ],
  },
];
export { MENUAdministrator };
