import React, { useEffect, Fragment, useContext } from "react";
import { Btn, Spinner, Ribbon } from "../../../AbstractElements";
import { useNavigate, useParams } from "react-router-dom";
import useState from "react-usestateref";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Label,
  FormGroup,
  Input,
  InputGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Api_CompanyListBasic, Api_CompanyCode } from "../../../api/Admin/Company";
import { Api_ProjectsList, Api_GetProjectCode } from "../../../api/Admin/Projects";
import { Api_PlantsList, Api_PlantsCode } from "../../../api/Admin/Plants";
import {
  Api_FillingMainStructuresListCompanyChild,
  Api_FillingMainStructuresListCompany_Parent_level1,
  Api_FillingCode,
  Api_FillingGetPath,
} from "../../../api/Admin/Filling";
import { Api_ProducerCompanyList } from "../../../api/Admin/ProducerCompany";
import { Api_AddDoc, Api_GetDoc, Api_EditDoc, Api_CheckAccess } from "../../../api/Admin/Doc";
import { Api_ProjectUnitListProject_Parent, Api_ProjectUnitListPlant, Api_ProjectUnitsCode } from "../../../api/Admin/ProjectUnit";
import { Api_PhaseListParent, Api_PhaseCode } from "../../../api/Admin/Phase";
import { Api_DocTypeListParent, Api_DocTypeCode } from "../../../api/Admin/DocType";
import { Api_DisciplineListParent, Api_DisciplineCode } from "../../../api/Admin/Discipline";
import { Api_SenderRecieverListParent, Api_SenderRecieverCode } from "../../../api/Admin/RecieverSender";
import { Api_GetUserAccessSecForDoc } from "../../../api/Admin/UserSecurityAccess";
import { DocumentAttach, DocLog, DocCodeShow } from ".";
import DatePicker from "react-datepicker";
import DeniReactTreeView from "deni-react-treeview";
import { format } from "date-fns";
import MemberTypeContext from "../../../_helper/MemberType/index";
const Document = () => {
  const { documentID } = useParams();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const navigate = useNavigate();
  const [senderrecievername, setsenderrecievername] = useState("");
  const [RDName, setRDName] = useState("");
  const [modalDocTypeTechnical, setmodalDocTypeTechnical] = useState(false);
  const [modalPhase, setmodalPhase] = useState(false);
  const [modalDiscipline, setmodalDiscipline] = useState(false);
  const [modalDocType, setmodalDocType] = useState(false);
  const [modalFilling, setmodalFilling] = useState(false);
  const [modalSenderReciever, setmodalSenderReciever] = useState(false);
  const [modalUnit, setmodalUnit] = useState(false);
  const [UnitName, setUnitName] = useState("");
  const [DocTypeName, setDocTypeName] = useState("");
  const [DisciplineName, setDisciplineName] = useState("");
  const [PhaseName, setPhaseName] = useState("");
  const [DocTypeTechnicalName, setDocTypeTechnicalName] = useState("");
  const [FillingName, setFillingName] = useState("");
  const [ShowFilling, setShowFilling] = useState(false);
  const [showUnit, setshowUnit] = useState(true);
  const [ShowYear, setShowYear] = useState(true);
  const [ShowIsReqUnit, setShowIsReqUnit] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [BreadcrumbsTitle, setBreadcrumbsTitle] = useState("");
  const [ListCompany, setListCompany] = useState([]);
  const [ListProject, setListProject] = useState([]);
  const [ListPlant, setListPlant] = useState([]);
  const [ListDocType, setListDocType] = useState([]);
  const [ListFilling, setListFilling] = useState([]);
  const [ListProducer, setListProducer] = useState([]);
  const [ListDiscipline, setListDiscipline] = useState([]);
  const [ListPhase, setListPhase] = useState([]);
  const [ListDocTypeTechnical, setListDocTypeTechnical] = useState([]);
  const [ListUnit, setListUnit] = useState([]);
  const [senderrecieverlist, setsenderrecieverlist] = useState([]);
  const [ProducerID, setProducerID] = useState(0);
  const [strSystemCode, setstrSystemCode, strSystemCodeRef] = useState([]);
  const [fileids, setfileids] = useState([]);
  const [disabledbtn, setdisabledbtn] = useState(false);
  const [disabledFiled, setdisabledFiled] = useState(false);
  const [ShowLetterPartner, setShowLetterPartner] = useState(false);
  const [ShowRecived, setShowRecived] = useState(false);
  const [PartnerName, setPartnerName] = useState(false);
  const [securityList, setsecurityList] = useState([]);
  const [obj, setobj, objRef] = useState({
    companyID: DefaultCompanyID,
    projectID: 0,
    plantID: 0,
    unitID: 0,
    docTypeID: 0,
    fillingID: null,
    disciplineID: null,
    phaseID: null,
    technicalDocTypeID: null,
    docTitle: "",
    docDescription: "",
    lastEdit: "",
    priority: "Medium",
    security: "LowSecret",
    transmital: "",
    letter: "",
    position: "Active",
    confrim: "Waiting",
    producerID: null,
    producerDocumentCode: "",
    userDocCode: "",
    serialID: 0,
    versionID: "",
    senderID: "",
    recieverID: "",
    ammendments: "",
    dateAdd: "",
    letterPartner: "",
    SystemCode: [],
    archiveNo: "",
    recivedBy: "EMail",
  });
  const [objSystemCode, setobjSystemCode, objSystemCodeRef] = useState({
    companyCode: "",
    projectCode: "",
    plantCode: "",
    doctypeCode: "",
    fillingCode: "",
    rd: "",
    producerode: "",
    year: "",
    unitcode: "",
    phasecode: "",
    disciplinecode: "",
    docTypeTechnicalcode: "",
  });
  const [objShowBtnAction, setobjShowBtnAction] = useState({
    add: false,
    delete: false,
    edit: false,
    see: false,
    fileAttcah: false,
  });
  const themes = ["classic"];
  const date = new Date();
  const [curDate, setcurDate] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const handleStartDate = (date) => {
    setstartDate(date);

    setobj({
      ...obj,
      dateAdd: date,
    });
    setobjSystemCode({
      ...objSystemCodeRef.current,
      year: date.getFullYear().toString().substring(2, 4),
    });
  };
  useEffect(() => {
    setcurDate(`${format(date, "do  MMMM yyyy")}`);

    const fetchData = async () => {
      try {
        LoadCompanyList();
        onChangeCompany(obj.companyID, false);
        CheckAccessBtn();
        AllAccessSec();
      } catch (err) {}
    };
    if (documentID) {
      setBreadcrumbsTitle("New Doucument");
      GetDataForEdit(documentID);
    } else {
      setBreadcrumbsTitle("New Doucument");
    }
    fetchData();
  }, []);
  const CheckAccessBtn = async () => {
    try {
      setLoading(true);
      const response = await Api_CheckAccess();
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        if (response.data.data != "") {
          setobjShowBtnAction(response.data.data);
        }
      }
    } catch (error) {}
  };
  const ReseteLevel1 = async () => {
    try {
      setDisciplineName("");
      setPhaseName("");
      setDocTypeTechnicalName("");
      setFillingName("");
      setShowIsReqUnit(false);
      setListDocTypeTechnical(null);
      setListPhase(null);
      setListDiscipline(null);
      setsenderrecievername("");
      setsenderrecieverlist(null);
      setListFilling(null);
    } catch (error) {
      console.log(error);
    }
  };
  const AllAccessSec = async () => {
    try {
      setLoading(true);
      const { data: response } = await Api_GetUserAccessSecForDoc();
      console.log(response.data);
      setsecurityList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const LoadCompanyList = async () => {
    try {
      setLoading(true);
      const response = await Api_CompanyListBasic();
      setListCompany(response.data.data);

      LoadProducerList(obj.companyID);
      setLoading(false);
    } catch (error) {}
  };
  const Hanldesetamcode = async (event) => {
    setobj({
      ...obj,
      ammendments: event,
    });
  };
  const LoadSenderRecierList = async (mod) => {
    try {
      setLoading(true);
      const response = await Api_SenderRecieverListParent(mod);
      setsenderrecieverlist(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadProducerList = async (val) => {
    try {
      setLoading(true);
      const response2 = await Api_ProducerCompanyList(val);
      setListProducer(response2.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadProjectList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectsList(id);
      setListProject(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadPlantList = async (id) => {
    try {
      setLoading(true);
      const response = await Api_PlantsList(id);
      setListPlant(response.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadUintOfPlant = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectUnitListPlant(id);
      setListUnit(response.data.data);
      setLoading(false);
      //console.log("Api_ProjectUnitListPlant:" + JSON.stringify(response.data.data));
    } catch (error) {}
  };
  const LoadUintOfProject = async (id) => {
    try {
      setLoading(true);
      const response = await Api_ProjectUnitListProject_Parent(id);
      setListUnit(response.data.data);
      setLoading(false);
      console.log("Api_ProjectUnitListPlant:" + JSON.stringify(response.data.data));
    } catch (error) {}
  };
  const LoadDocType = async (mod, id) => {
    try {
      setLoading(true);
      setListFilling(null);
      if (mod == "company") {
        const response = await Api_FillingMainStructuresListCompany_Parent_level1(id);
        setListDocType(response.data.data);
      }
      //  else if (mod == "project") {
      //   const response = await Api_FillingMainStructuresListProject_Parent(id);
      //   setListDocType(response.data.data);
      // } else if (mod == "plant") {
      //   const response = await Api_FillingMainStructuresListPlant_Parent(id);
      //   setListDocType(response.data.data);
      // }
      else {
        //console.log(JSON.stringify(obj));
      }
      setLoading(false);
    } catch (error) {}
  };
  const LoadFilling = async (data, parent) => {
    try {
      setLoading(true);
      const response = await Api_FillingMainStructuresListCompanyChild(data.companyID, parent);

      setListFilling(response.data.data);
      // if (data.companyID > 0 && data.projectID == null && data.plantID == null) {
      //   const response = await Api_FillingMainStructuresListCompanyChild(data.companyID, parent);
      //   setListFilling(response.data.data);
      //   console.log("a");
      // } else if (data.companyID > 0 && data.projectID != null && data.plantID == null) {
      //   const response = await Api_FillingMainStructuresListProjectChild(data.projectID, parent);
      //   setListFilling(response.data.data);
      //   console.log(JSON.stringify(response));
      // } else if (data.companyID > 0 && data.projectID == null && data.plantID != null) {
      //   const response = await Api_FillingMainStructuresListPlantChild(data.plantID, parent);
      //   setListFilling(response.data.data);
      //   console.log("c");
      // }
      setLoading(false);
    } catch (error) {}
  };
  const LoadTechnicalParts = async () => {
    try {
      setLoading(true);
      const response1 = await Api_DisciplineListParent(0, "TechnicalDocuments");
      setListDiscipline(response1.data.data);
      const response2 = await Api_DocTypeListParent();
      setListDocTypeTechnical(response2.data.data);
      const response3 = await Api_PhaseListParent();
      setListPhase(response3.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const LoadDiscipline = async (id) => {
    try {
      setLoading(true);
      const response1 = await Api_DisciplineListParent(id, "Contractors");
      setListDiscipline(response1.data.data);
      setLoading(false);
    } catch (error) {}
  };
  const onChangeCompany = (val, reseteObjSyscode) => {
    setUnitName("");
    setsenderrecievername("");
    setsenderrecieverlist(null);
    setobj({
      ...obj,
      companyID: val,
      unitID: null,
      dateAdd: date,
      producerode: "",
      docTypeID: 0,
      fillingID: null,
      projectID: 0,
      plantID: 0,
      unitID: 0,
    });
    LoadProjectList(val);
    LoadPlantList(val);
    LoadDocType("company", val);
    GetCompanyCode(val, reseteObjSyscode);
    ReseteLevel1();
    LoadProducerList(val);
    setDocTypeName("");
  };
  const GetCompanyCode = async (id, reseteObjSyscode) => {
    console.log("id:" + id);
    console.log("reseteObjSyscode:" + reseteObjSyscode);
    try {
      if (id > 0) {
        const response = await Api_CompanyCode(id);
        if (reseteObjSyscode) {
          setobjSystemCode({
            ...objSystemCodeRef.current,
            companyCode: response.data.data.code,
            projectCode: "",
            plantCode: "",
            doctypeCode: "",
            fillingCode: "",
            rd: "",
            producerode: "",
            unitcode: "",
            phasecode: "",
            disciplinecode: "",
            docTypeTechnicalcode: "",
            year: response.data.data.year,
          });
        } else {
          setobjSystemCode({
            ...objSystemCodeRef.current,
            companyCode: response.data.data.code,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetProjectCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_GetProjectCode(id);
        setobjSystemCode({
          ...objSystemCodeRef.current,
          projectCode: response.data.data.code,
          fillingCode: "",
          disciplinecode: "",
          phasecode: "",
          docTypeTechnicalcode: "",
          plantCode: "",
        });
      } else {
        setobjSystemCode({
          ...objSystemCodeRef.current,
          projectCode: "",
          fillingCode: "",
          disciplinecode: "",
          phasecode: "",
          docTypeTechnicalcode: "",
          plantCode: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetPlantCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_PlantsCode(id);
        setobjSystemCode({
          ...objSystemCodeRef.current,
          plantCode: response.data.data.code,
          fillingCode: "",
          disciplinecode: "",
          phasecode: "",
          docTypeTechnicalcode: "",
          projectCode: "",
        });
      } else {
        setobjSystemCode({
          ...objSystemCodeRef.current,
          plantCode: "",
          fillingCode: "",
          disciplinecode: "",
          phasecode: "",
          docTypeTechnicalcode: "",
          projectCode: "",
        });
      }
      //UpdateSystemCode();
    } catch (error) {
      console.log(error);
    }
  };
  const GetDocTypeCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_FillingCode(id);

        if (response.data.data.code.toUpperCase() == "L") {
          setshowUnit(false);
          setobjSystemCode({
            ...objSystemCodeRef.current,
            unitcode: "",
          });
        } else {
          setshowUnit(true);
        }

        if (response.data.data.code.toUpperCase() == "D") {
          if (objSystemCode.projectCode != "" || objSystemCode.plantCode != "") {
            setShowIsReqUnit(true);
          } else {
            setShowIsReqUnit(false);
          }
          setShowFilling(false);
          LoadTechnicalParts();
        } else {
          LoadFilling(obj, id);
          setShowFilling(true);
          setShowIsReqUnit(false);
        }

        if (response.data.data.code.toUpperCase() == "L") {
          setShowLetterPartner(true);
          setShowRecived(true);
          setPartnerName("Letter Partner");
        } else if (response.data.data.code.toUpperCase() == "C") {
          setShowLetterPartner(true);
          setShowRecived(true);
          setPartnerName("Contract Partner");
        } else if (response.data.data.code.toUpperCase() == "B") {
          setShowLetterPartner(true);
          setShowRecived(true);
          setPartnerName("Partner");
        } else if (response.data.data.code.toUpperCase() == "M") {
          setShowRecived(true);
        } else {
          setShowLetterPartner(false);
          setShowRecived(false);
        }
        setobjSystemCode({
          ...objSystemCodeRef.current,
          doctypeCode: response.data.data.code,
          fillingCode: "",
          disciplinecode: "",
          phasecode: "",
          docTypeTechnicalcode: "",
        });

        setobj({
          ...objRef.current,
          security: response.data.data.security,
        });
      }
      //UpdateSystemCode();
    } catch (error) {
      console.log(error);
    }
  };
  const GetFillingCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_FillingCode(id);
        console.log(JSON.stringify(response.data.data));
        setobjSystemCode({
          ...objSystemCodeRef.current,
          fillingCode: response.data.data.code,
        });
        setobj({
          ...objRef.current,
          security: response.data.data.security,
        });
        if (objSystemCode.doctypeCode.toUpperCase() == "L" && response.data.data.code.toUpperCase() == "ER") {
          setRDName("Reciever");
          LoadSenderRecierList("Sender");
        } else if (objSystemCode.doctypeCode.toUpperCase() == "L" && response.data.data.code.toUpperCase() == "ED") {
          setRDName("Sender");
          LoadSenderRecierList("Reciever");
        }
      }
      //UpdateSystemCode();
    } catch (error) {
      console.log(error);
    }
  };
  const GetSenderRecieverCode = async (id) => {
    try {
      if (id > 0) {
        const response = await Api_SenderRecieverCode(id);
        setobjSystemCode({
          ...objSystemCodeRef.current,
          rd: response.data.data.code,
        });
      } else {
        setobjSystemCode({
          ...objSystemCodeRef.current,
          rd: "",
        });
      }
      //UpdateSystemCode();
    } catch (error) {
      console.log(error);
    }
  };
  const onObjChange = async (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeProject = (val) => {
    ReseteLevel1();
    setobjSystemCode({
      ...objSystemCodeRef.current,
      doctypeCode: "",
      fillingCode: "",
    });
    setDocTypeName("");
    setFillingName("");
    if (val > 0) {
      setobj({
        ...obj,
        projectID: val,
        plantID: null,
        unitID: null,
        fillingID: null,
        docTypeID: 0,
      });

      LoadUintOfProject(val);
      GetProjectCode(val);
    } else {
      setobj({
        ...obj,
        projectID: null,
        unitID: null,
        fillingID: null,
        docTypeID: 0,
        plantID: null,
      });

      setListUnit(null);
      GetProjectCode(0);
    }
  };
  const onChangePlant = (val) => {
    ReseteLevel1();
    setobjSystemCode({
      ...objSystemCodeRef.current,
      doctypeCode: "",
      fillingCode: "",
    });
    if (val > 0) {
      setobj({
        ...obj,
        plantID: val,
        projectID: 0,
        unitID: null,
        fillingID: null,
        docTypeID: 0,
      });

      LoadUintOfPlant(val);
      GetPlantCode(val);
      setDocTypeName("");
      setFillingName("");
    } else {
      setobj({
        ...obj,
        plantID: null,
        unitID: null,
        fillingID: null,
        docTypeID: null,
        projectID: 0,
      });
      setDocTypeName("");
      setFillingName("");
      setListUnit(null);
      GetPlantCode(0);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSelectItemDocType = async (item) => {
    try {
      setmodalDocType(false);
      setDocTypeName(item.text);
      ReseteLevel1();
      GetDocTypeCode(item.id);
      setobj({
        ...obj,
        docTypeID: item.id,
        fillingID: null,
      });
    } catch (error) {
      console.log("error  :" + error);
    }
  };
  const onSelectItemFilling = async (item) => {
    try {
      setsenderrecievername("");
      setsenderrecieverlist(null);
      setDisciplineName("");
      setobj({
        ...obj,
        fillingID: item.id,
      });

      const response = await Api_FillingGetPath(item.id, obj.companyID);

      setFillingName(response.data.message);
      setmodalFilling(false);
      GetFillingCode(item.id);

      if (objSystemCode.doctypeCode.toUpperCase() == "V") {
        LoadDiscipline(item.id);
      } else {
        setListDiscipline(null);
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemUnit = async (item) => {
    try {
      if (item.isLeaf == "true") {
        const response = await Api_ProjectUnitsCode(item.id);

        setobjSystemCode({
          ...objSystemCodeRef.current,
          unitcode: response.data.data.code,
        });

        setUnitName(item.text);
        setobj({
          ...obj,
          unitID: item.id,
        });
        setmodalUnit(false);
      }
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onRenderItem = (item) => {
    return (
      <div className="treeview-item-example">
        <span onClick={() => onSelectItemUnit(item)} className="treeview-item-example-text">
          {item.text}
        </span>
        {item.code != null ? <span className="actionButton trash">({item.code})</span> : null}
      </div>
    );
  };
  const onSelectItemSenderReciever = async (item) => {
    try {
      if (objSystemCode.doctypeCode.toUpperCase() == "L" && objSystemCode.fillingCode.toUpperCase() == "ER") {
        setobj({
          ...obj,
          recieverID: item.id,
        });
      } else if (objSystemCode.doctypeCode.toUpperCase() == "L" && objSystemCode.fillingCode.toUpperCase() == "ED") {
        setobj({
          ...obj,
          senderID: item.id,
        });
      }
      setsenderrecievername(item.text);
      setmodalSenderReciever(false);
      GetSenderRecieverCode(item.id);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemDocTypeTechnical = async (item) => {
    try {
      const response = await Api_DocTypeCode(item.id);
      setobjSystemCode({
        ...objSystemCodeRef.current,
        docTypeTechnicalcode: response.data.data.code,
      });
      setobj({
        ...obj,
        technicalDocTypeID: item.id,
      });
      setDocTypeTechnicalName(item.text);
      setmodalDocTypeTechnical(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const onSelectItemPhase = async (item) => {
    try {
      const response = await Api_PhaseCode(item.id);
      setobjSystemCode({
        ...objSystemCodeRef.current,
        phasecode: response.data.data.code,
      });

      setobj({
        ...obj,
        phaseID: item.id,
      });
      setPhaseName(item.text);
      setmodalPhase(false);
    } catch (error) {}
  };
  const onSelectItemDiscipline = async (item) => {
    try {
      const response = await Api_DisciplineCode(item.id);

      setobjSystemCode({
        ...objSystemCodeRef.current,
        disciplinecode: response.data.data.code,
      });
      setobj({
        ...obj,
        disciplineID: item.id,
      });
      setDisciplineName(item.text);
      setmodalDiscipline(false);
    } catch (error) {
      console.log("error:" + error);
    }
  };
  const CloseForm = () => {
    navigate(`${process.env.PUBLIC_URL}/admin/Document/add`);
  };
  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetDoc(id);
      console.log("response:" + JSON.stringify(response.data.data.objSystemCode));
      if (response.status == "200" && response.data.isSuccess == true) {
        setobj({
          projectID: response.data.data.projectID,
          plantID: response.data.data.plantID,
          docTypeID: response.data.data.docTypeID,
          fillingID: response.data.data.fillingID,
          docDescription: response.data.data.docDescription,
          unitID: response.data.data.unitID,
          companyID: response.data.data.companyID,
          lastEdit: response.data.data.lastEdit,
          priority: response.data.data.priority,
          security: response.data.data.security,
          transmital: response.data.data.transmital,
          letter: response.data.data.letter,
          producerID: response.data.data.producerID,
          position: response.data.data.position,
          confrim: response.data.data.confrim,
          docTitle: response.data.data.docTitle,
          userDocCode: response.data.data.userDocCode,
          id: response.data.data.id,
          producerDocumentCode: response.data.data.producerDocumentCode,
          versionID: response.data.data.versionID,
          serialID: response.data.data.serialID,
          ammendments: response.data.data.ammendments,
          dateAdd: response.data.data.dateAdd,
          letterPartner: response.data.data.letterPartner,
          archiveNo: response.data.data.archiveNo,
          recivedBy: response.data.data.recivedBy,
        });
        const dateadd = new Date(response.data.data.dateAdd.toString().substring(0, 10));

        if (response.data.data.confrim == "Yes") {
          setdisabledFiled(true);
        }
        setstartDate(dateadd);

        if (response.data.data.docTypeID != null) {
          LoadFilling(response.data.data, response.data.data.docTypeID);
        }
        if (response.data.data.fillingID != null) {
          setShowFilling(true);
        }
        if (response.data.data.unitID != null) {
          setshowUnit(true);
          setUnitName(response.data.data.projectUnitsTitle);
          if (response.data.data.projectID != null) {
            LoadUintOfProject(response.data.data.projectID);
          }
          if (response.data.data.plantID != null) {
            LoadUintOfPlant(response.data.data.plantID);
          }
        }
        setDocTypeName(response.data.data.fillingMainStructure1Description);
        setFillingName(response.data.data.fillingMainStructure2Description);

        if (response.data.data.recieverDescription != null) {
          setsenderrecievername(response.data.data.recieverDescription);
          setRDName("Reciever");
        } else if (response.data.data.senderDescription != null) {
          setsenderrecievername(response.data.data.senderDescription);
          setRDName("Sender");
        }
        if (response.data.data.producerID != null) {
          setProducerID(response.data.data.producerID);
        }
        if (response.data.data.fillingMainStructure1Code.toUpperCase() == "D") {
          setPhaseName(response.data.data.phaseDescription);
          setDocTypeTechnicalName(response.data.data.technicalDocTypeDescription);
          setDisciplineName(response.data.data.disciplineDescription);
        }

        //partner Code
        if (response.data.data.fillingMainStructure1Code.toUpperCase() == "L") {
          setShowLetterPartner(true);
          setPartnerName("Letter Partner");
        } else if (response.data.data.fillingMainStructure1Code.toUpperCase() == "C") {
          setShowLetterPartner(true);
          setPartnerName("Contract Partner");
        } else if (response.data.data.fillingMainStructure1Code.toUpperCase() == "B") {
          setShowLetterPartner(true);
          setPartnerName("Partner");
        } else {
          setShowLetterPartner(false);
        }

        if (response.data.data.companyCode != null) {
          setobjSystemCode({
            ...objSystemCodeRef.current,
            companyCode: response.data.data.companyCode,
            projectCode: response.data.data.projectCode,
            plantCode: response.data.data.plantCode,
            doctypeCode: response.data.data.doctypeCode,
            fillingCode: response.data.data.fillingCode,
            rd: response.data.data.rd,
            producerode: response.data.data.producerode,
            year: response.data.data.year,
            unitcode: response.data.data.unitcode,
            phasecode: response.data.data.phasecode,
            disciplinecode: response.data.data.disciplinecode,
            docTypeTechnicalcode: response.data.data.docTypeTechnicalcode,
          });
        } else {
          setstrSystemCode(response.data.data.systemCode.split(" "));
        }
      } else {
        if (response.response.status == "500") {
          toast.error(response.response.data.message);
        } else {
          toast.error("Error");
        }
      }

      setLoading(false);
    } catch (err) {
      console.log("err:" + err.message);
    }
  };
  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (documentID) {
        setobj({
          ...obj,
          SystemCode: objSystemCode,
        });
        const response = await Api_EditDoc(documentID, objRef.current);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Your document has been edited");
          navigate(`${process.env.PUBLIC_URL}/admin/Document/edit/${response.data.message}`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      } else {
        setobj({
          ...obj,
          SystemCode: objSystemCode,
        });
        const response = await Api_AddDoc(objRef.current);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("New Document Added");
          navigate(`${process.env.PUBLIC_URL}/admin/Document/edit/${response.data.message}`);
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
      setLoadingBtn(false);
    } catch (error) {
      setLoadingBtn(false);
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Doc.Management" title={BreadcrumbsTitle} Loading={Loading} />
          <Row>
            <Col sm="12" md="12" lg="12">
              <Card className="ribbon-wrapper-right">
                <CardBody>
                  <Ribbon
                    attrRibbons={{
                      className: "ribbon ribbon ribbon-clip ribbon-secondary",
                    }}
                  >
                    Company
                  </Ribbon>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Company"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          type="select"
                          name="companyID"
                          className="form-control digits"
                          value={obj.companyID}
                          disabled={disabledFiled ? true : false}
                          onChange={(txt) => {
                            onChangeCompany(txt.target.value, true);
                          }}
                        >
                          {ListCompany != null
                            ? ListCompany.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Project"}</Label>
                        <Input
                          type="select"
                          name="projectID"
                          disabled={disabledFiled ? true : false}
                          className="form-control digits"
                          value={obj.projectID != null ? obj.projectID : 0}
                          onChange={(txt) => {
                            onChangeProject(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListProject != null
                            ? ListProject.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Plant"}</Label>
                        <Input
                          type="select"
                          name="plantID"
                          disabled={disabledFiled ? true : false}
                          className="form-control digits"
                          value={obj.plantID != null ? obj.plantID : 0}
                          onChange={(txt) => {
                            onChangePlant(txt.target.value);
                          }}
                        >
                          <option value={0} key={0}>
                            Select...
                          </option>
                          {ListPlant != null
                            ? ListPlant.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.description}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>

                    {(obj.projectID != null || obj.plantID != null) && showUnit == true ? (
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">{"Unit"}</Label>
                          {ShowIsReqUnit ? <label className="txt-danger Required">{"*"}</label> : null}
                          {ListUnit != null && ListUnit != "" ? (
                            <Input
                              className="form-control"
                              value={UnitName}
                              onClick={() => {
                                setmodalUnit(true);
                              }}
                              readOnly={true}
                            />
                          ) : (
                            <Input
                              className="form-control"
                              value={""}
                              onClick={() => {
                                toast.info("List Is Empty");
                              }}
                              readOnly={true}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" md="12" lg="12">
              <Row>
                <Col sm="7">
                  <Row>
                    <Col sm="12">
                      <Card className="ribbon-wrapper-right">
                        <CardBody>
                          <Ribbon
                            attrRibbons={{
                              className: "ribbon ribbon ribbon-clip ribbon-secondary",
                            }}
                          >
                            Document Data
                          </Ribbon>
                          <Row>
                            <Col sm="12">
                              <FormGroup>
                                <Label>{"Doc.Title"}</Label>
                                <label className="txt-danger Required">{"*"}</label>
                                <InputGroup>
                                  <Input
                                    name="docTitle"
                                    maxLength={255}
                                    className="form-control"
                                    value={obj.docTitle || ""}
                                    onChange={onObjChange}
                                    required={true}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col sm="12">
                              <FormGroup>
                                <Label htmlFor="exampleFormControlSelect9">{"Doc.Type"}</Label>
                                <label className="txt-danger Required">{"*"}</label>
                                {ListDocType != null && ListDocType != "" ? (
                                  <Input
                                    className="form-control"
                                    disabled={disabledFiled ? true : false}
                                    value={DocTypeName}
                                    onClick={() => {
                                      setmodalDocType(true);
                                    }}
                                    readOnly={true}
                                  />
                                ) : (
                                  <Input
                                    className="form-control"
                                    value={""}
                                    onClick={() => {
                                      toast.info("List Is Empty");
                                    }}
                                    readOnly={true}
                                  />
                                )}
                              </FormGroup>
                            </Col>
                            {ShowFilling == true ? (
                              <Col sm="12">
                                <FormGroup>
                                  <Label htmlFor="exampleFormControlSelect9">{"Filling"}</Label>
                                  <label className="txt-danger Required">{"*"}</label>
                                  {ListFilling != null && ListFilling != "" ? (
                                    <Input
                                      className="form-control"
                                      disabled={disabledFiled ? true : false}
                                      value={FillingName}
                                      onClick={() => {
                                        setmodalFilling(true);
                                      }}
                                      readOnly={true}
                                    />
                                  ) : (
                                    <Input
                                      className="form-control"
                                      value={""}
                                      onClick={() => {
                                        toast.info("List Is Empty");
                                      }}
                                      readOnly={true}
                                    />
                                  )}
                                </FormGroup>
                              </Col>
                            ) : null}
                            {(ListPhase != null && ListPhase != "" && ShowFilling == false) || (ShowFilling == false && PhaseName != "") ? (
                              <Col sm="4">
                                <FormGroup>
                                  <Label htmlFor="exampleFormControlSelect9">{"Phase"}</Label>
                                  <label className="txt-danger Required">{"*"}</label>
                                  <Input
                                    className="form-control"
                                    disabled={disabledFiled ? true : false}
                                    value={PhaseName}
                                    onClick={() => {
                                      setmodalPhase(true);
                                    }}
                                    readOnly={true}
                                  />
                                </FormGroup>
                              </Col>
                            ) : null}

                            {(ListDiscipline != null && ListDiscipline != "") || (ShowFilling == false && DisciplineName != "") ? (
                              <Col sm={objSystemCode.doctypeCode.toUpperCase() == "V" ? 12 : 4}>
                                <FormGroup>
                                  <Label htmlFor="exampleFormControlSelect9">{"Discipline"}</Label>
                                  <label className="txt-danger Required">{"*"}</label>
                                  <Input
                                    className="form-control"
                                    disabled={disabledFiled ? true : false}
                                    value={DisciplineName}
                                    onClick={() => {
                                      setmodalDiscipline(true);
                                    }}
                                    readOnly={true}
                                  />
                                </FormGroup>
                              </Col>
                            ) : null}
                            {(ListDocTypeTechnical != null && ListDocTypeTechnical != "") || (ShowFilling == false && DocTypeTechnicalName != "") ? (
                              <Col sm="4">
                                <FormGroup>
                                  <Label htmlFor="exampleFormControlSelect9">{"Doc.Type"}</Label>
                                  <label className="txt-danger Required">{"*"}</label>
                                  <Input
                                    className="form-control"
                                    disabled={disabledFiled ? true : false}
                                    value={DocTypeTechnicalName}
                                    onClick={() => {
                                      setmodalDocTypeTechnical(true);
                                    }}
                                    readOnly={true}
                                  />
                                </FormGroup>
                              </Col>
                            ) : null}
                            {(senderrecieverlist != null && senderrecieverlist != "") || senderrecievername != "" ? (
                              <Col sm="12">
                                <FormGroup>
                                  <Label htmlFor="exampleFormControlSelect9">{RDName}</Label>
                                  <Input
                                    className="form-control"
                                    disabled={disabledFiled ? true : false}
                                    value={senderrecievername}
                                    onClick={() => {
                                      setmodalSenderReciever(true);
                                    }}
                                    readOnly={true}
                                  />
                                </FormGroup>
                              </Col>
                            ) : null}
                            {ShowLetterPartner ? (
                              <Col sm="6">
                                <FormGroup>
                                  <Label>{PartnerName}</Label>
                                  <InputGroup>
                                    <Input
                                      name="letterPartner"
                                      type="text"
                                      maxLength={100}
                                      className="form-control"
                                      value={obj.letterPartner || ""}
                                      onChange={onObjChange}
                                      autoComplete="off"
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            ) : null}
                            {ShowRecived ? (
                              <Col sm="6">
                                <FormGroup>
                                  <Label>Recieved / Dispatched by:</Label> <label className="txt-danger Required">{"*"}</label>
                                  <Input type="select" name="recivedBy" className="form-control" value={obj.recivedBy} onChange={onObjChange}>
                                    <option value="Post" key="Post">
                                      Post
                                    </option>
                                    <option value="EMail" key="EMail">
                                      EMail
                                    </option>
                                    <option value="ByHand" key="ByHand">
                                      By Hand
                                    </option>
                                    <option value="SocialNetworks" key="SocialNetworks">
                                      Social Networks
                                    </option>
                                    <option value="InternalNetworks" key="InternalNetworks">
                                      Internal Networks
                                    </option>
                                    <option value="Others" key="Others">
                                      Others
                                    </option>
                                  </Input>
                                </FormGroup>
                              </Col>
                            ) : null}
                            <Col sm="12">
                              <FormGroup>
                                <Label>{"Doc.Description"}</Label>
                                <InputGroup>
                                  <Input
                                    name="docDescription"
                                    type="textarea"
                                    maxLength={255}
                                    className="form-control"
                                    value={obj.docDescription || ""}
                                    onChange={onObjChange}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="exampleFormControlSelect9">{"Position"}</Label>
                                <label className="txt-danger Required">{"*"}</label>
                                <Input type="select" name="position" className="form-control" value={obj.position} onChange={onObjChange}>
                                  <option value="Active" key="Active">
                                    Active
                                  </option>
                                  <option value="Inactive" key="Inactive">
                                    Inactive
                                  </option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label>Doc.Date</Label>
                                <DatePicker
                                  className="datepicker-here form-control"
                                  peekNextMonth={false}
                                  dateFormat="MMMM d, yyyy"
                                  selected={startDate}
                                  onChange={handleStartDate}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col sm="5">
                  <Row>
                    <Col sm="12">
                      <Card className="ribbon-wrapper-right">
                        <CardBody>
                          <Ribbon
                            attrRibbons={{
                              className: "ribbon ribbon ribbon-clip ribbon-secondary",
                            }}
                          >
                            Document Condition
                          </Ribbon>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="exampleFormControlSelect9">{"Priority"}</Label>
                                <label className="txt-danger Required">{"*"}</label>
                                <Input type="select" name="priority" className="form-control" value={obj.priority} onChange={onObjChange}>
                                  <option value="Top" key="Top">
                                    Top
                                  </option>
                                  <option value="Medium" key="Medium">
                                    Medium
                                  </option>
                                  <option value="Low" key="Low">
                                    Low
                                  </option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label htmlFor="exampleFormControlSelect9">{"Security"}</Label>
                                <label className="txt-danger Required">{"*"}</label>
                                <Input type="select" name="security" className="form-control" value={obj.security} onChange={onObjChange}>
                                  {/* <option value="TopSecret" key="TopSecret">
                                    Top Secret
                                  </option>
                                  <option value="Medium" key="Medium">
                                    Medium
                                  </option>
                                  <option value="LowSecret" key="LowSecret">
                                    Low Secret
                                  </option>
                                  <option value="NonSecret" key="NonSecret">
                                    Non Secret
                                  </option> */}
                                  {securityList != null && securityList != ""
                                    ? securityList.map((item) => {
                                        return (
                                          <option value={item.value} key={item.value}>
                                            {item.label}
                                          </option>
                                        );
                                      })
                                    : null}
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="12">
                      <Card className="ribbon-wrapper-right">
                        <CardBody>
                          <Ribbon
                            attrRibbons={{
                              className: "ribbon ribbon ribbon-clip ribbon-secondary",
                            }}
                          >
                            Document Attachment
                          </Ribbon>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                                <Label>{"Transmital"}</Label>
                                <InputGroup>
                                  <Input
                                    name="transmital"
                                    maxLength={255}
                                    className="form-control"
                                    onChange={onObjChange}
                                    value={obj.transmital || ""}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                            <Col sm="6">
                              <FormGroup>
                                <Label>{"Letter"}</Label>
                                <InputGroup>
                                  <Input
                                    name="letter"
                                    maxLength={255}
                                    className="form-control"
                                    onChange={onObjChange}
                                    value={obj.letter || ""}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm="12">
                      <Card className="ribbon-wrapper-right">
                        <CardBody>
                          <Ribbon
                            attrRibbons={{
                              className: "ribbon ribbon ribbon-clip ribbon-secondary",
                            }}
                          >
                            Document Producer
                          </Ribbon>
                          <Row>
                            <Col sm="12">
                              <FormGroup>
                                <Label htmlFor="exampleFormControlSelect9">
                                  {"Company"}
                                  {/* <i title="Click For Add a New Producer" className="fa fa-plus-square-o" onClick={() => SetModNewProduser()}></i> */}
                                </Label>

                                <Input
                                  type="select"
                                  name="producerID"
                                  className="form-control"
                                  value={obj.producerID == null ? 0 : obj.producerID}
                                  onChange={onObjChange}
                                >
                                  <option value={0} key={0}>
                                    Select...
                                  </option>
                                  {ListProducer != null
                                    ? ListProducer.map((item) => {
                                        return (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })
                                    : null}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col sm="12">
                              <FormGroup>
                                <Label>{"Producer Document Code"}</Label>
                                <InputGroup>
                                  <Input
                                    name="producerDocumentCode"
                                    maxLength={255}
                                    className="form-control"
                                    value={obj.producerDocumentCode || ""}
                                    onChange={onObjChange}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card className="ribbon-wrapper-right">
                <CardBody>
                  <Ribbon
                    attrRibbons={{
                      className: "ribbon ribbon ribbon-clip ribbon-secondary",
                    }}
                  >
                    Code
                  </Ribbon>
                  <Row>
                    <Col sm="2">
                      <FormGroup>
                        <Label>{"User Document Code"}</Label>

                        <InputGroup>
                          <Input
                            name="userDocCode"
                            maxLength={255}
                            className="form-control"
                            value={obj.userDocCode || ""}
                            onChange={onObjChange}
                            autoComplete="off"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    {/* SystemCode */}
                    <DocCodeShow
                      objSystemCode={objSystemCodeRef.current}
                      obj={obj}
                      versionID={obj.versionID}
                      ShowYear={ShowYear}
                      strSystemCode={strSystemCodeRef.current}
                      Hanldesetamcode={Hanldesetamcode}
                    />

                    <Col sm="1">
                      <FormGroup>
                        <Label>{"Archive No"}</Label>
                        <InputGroup>
                          <Input name="userDocCode" className="form-control" value={obj.archiveNo || ""} disabled={true} autoComplete="off" />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {documentID ? (
            <DocumentAttach
              openfrom={"doc"}
              serialID={obj.serialID}
              disabledbtn={false}
              setdisabledbtn={setdisabledbtn}
              obj={obj}
              documentID={documentID}
              fileAttcah={objShowBtnAction.fileAttach}
            />
          ) : (
            <DocumentAttach
              fileAttcah={objShowBtnAction.fileAttach}
              openfrom={"doc"}
              serialID={obj.serialID}
              disabledbtn={true}
              obj={obj}
              documentID={0}
            />
          )}
          {documentID ? <DocLog documentID={documentID} /> : null}

          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {documentID && obj.confrim != "Deleted" ? (
                    <Button className="btn btn-success btn-md m-r-15">
                      {LoadingBtn ? (
                        <div className="loader-box2">
                          <Spinner attrSpinner={{ className: "loader-7" }} />
                        </div>
                      ) : documentID ? (
                        "Save"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  ) : (
                    <Button className="btn btn-success btn-md m-r-15">
                      {LoadingBtn ? (
                        <div className="loader-box2">
                          <Spinner attrSpinner={{ className: "loader-7" }} />
                        </div>
                      ) : documentID ? (
                        "Save"
                      ) : (
                        "Add"
                      )}
                    </Button>
                  )}

                  {/* {documentID ? ( */}
                  <Btn
                    attrBtn={{
                      color: "light",
                      type: "button",
                      onClick: () => CloseForm(),
                    }}
                  >
                    Close
                  </Btn>

                  {/* ) : null} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
      <Modal isOpen={modalDocType}>
        <ModalHeader>Doc.Type</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItemDocType}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDocType}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalDocType(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalFilling}>
        <ModalHeader>
          Doc.Type: {DocTypeName}+<br></br>Filling:{FillingName}
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  getRowId={(originalRow) => originalRow.id}
                  onSelectItem={onSelectItemFilling}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListFilling}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalFilling(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalUnit}>
        <ModalHeader>Unit</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  selectRow={true}
                  onRenderItem={onRenderItem}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListUnit}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalUnit(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalSenderReciever}>
        <ModalHeader>Select {RDName}</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemSenderReciever}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={senderrecieverlist}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalSenderReciever(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDiscipline}>
        <ModalHeader>Discipline</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemDiscipline}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDiscipline}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalDiscipline(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalPhase}>
        <ModalHeader>Phase</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemPhase}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListPhase}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalPhase(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalDocTypeTechnical}>
        <ModalHeader>Doc.Type</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {themes.map((theme, index) => {
              return (
                <DeniReactTreeView
                  onSelectItem={onSelectItemDocTypeTechnical}
                  getRowId={(originalRow) => originalRow.id}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                  key={index}
                  showCheckbox={false}
                  theme={theme}
                  items={ListDocTypeTechnical}
                />
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setmodalDocTypeTechnical(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Document;
