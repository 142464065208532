import React, { useEffect, Fragment, useMemo, useRef, useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Btn, H6, Image, P, Spinner } from "../../../AbstractElements";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { Card, CardBody, Col, Container, Row, Form, Label, FormGroup, Input, InputGroup, Button } from "reactstrap";
import MemberTypeContext from "../../../_helper/MemberType/index";
import { Api_CompanyListBasic } from "../../../api/Admin/Company";
import useState from "react-usestateref";
import {
  Api_AdviceQuestionList,
  Api_GetAdviceQuestion,
  Api_EditAdviceQuestion,
  Api_AddAdviceQuestion,
  Api_DeleteAdviceQuestion,
} from "../../../api/Admin/AdviceQuestion";
import { Api_AdviceQuestionTitleCategoryList } from "../../../api/Admin/AdviceQuestionTitle";
const QuestionAdd = () => {
  const editorRef = useRef(null);
  const { adviceID } = useParams();
  const navigate = useNavigate();
  const { typeName } = useContext(MemberTypeContext);
  let { DefaultCompanyID } = typeName;
  const [ListTitle, setListTitle] = useState(null);
  const [SelectedRowID, setSelectedRowID] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [LoadingBtn, setLoadingBtn] = useState(false);
  const [obj, setobj, objRef] = useState({
    id: 0,
    text: "",
    titleCategoryID: 0,
  });

  useEffect(() => {
    LoadData();
    if (adviceID) {
      GetDataForEdit(adviceID);
    }
  }, []);

  const onChangeCategory = (val) => {
    if (val > 0) {
      setobj({
        ...obj,
        titleCategoryID: val,
      });
    } else {
      setobj({
        ...obj,
        titleCategoryID: 0,
      });
    }
  };

  const LoadData = async () => {
    try {
      setLoading(true);
      const response = await Api_AdviceQuestionTitleCategoryList("Question");
      setListTitle(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    try {
      setLoadingBtn(true);
      if (SelectedRowID == 0) {
        setobj({
          ...obj,
          text: editorRef.current.getContent(),
        });
        const backupobj = objRef.current;

        const response = await Api_AddAdviceQuestion(backupobj);

        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("A new Question has been added");

          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("error");
        }
      } else {
        setobj({
          ...obj,
          text: editorRef.current.getContent(),
        });
        const backupobj = objRef.current;

        const response = await Api_EditAdviceQuestion(SelectedRowID, backupobj);
        setLoadingBtn(false);
        if (response.status && response.status == 200 && response.data.isSuccess == true) {
          toast.success("Information edited successfully");
          setSelectedRowID(0);
          ResetForm();
        } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
          toast.error("You do not have access permission");
        } else if (response.response && response.response.data.isSuccess == false) {
          toast.error(response.response.data.message);
        } else if (response.data && response.data.isSuccess == false) {
          toast.error(response.data.message);
        } else {
          toast.error("Error - 1");
        }
      }
    } catch (error) {
      toast.error("Error - 0");
    }
  };
  const onError = (errors, e) => console.log(errors, e);
  const onInputChange = (event) => {
    setobj({
      ...obj,
      [event.target.name]: event.target.value,
    });
  };

  const GetDataForEdit = async (id) => {
    try {
      setLoading(true);
      const response = await Api_GetAdviceQuestion(id);
      console.log("response:" + JSON.stringify(response.data));
      console.log("response:" + response.data.data);
      if (response.status && response.status == 200 && response.data.isSuccess == true) {
        setSelectedRowID(id);
        setobj({
          text: response.data.data.text,
          titleCategoryID: response.data.data.titleCategoryID,
        });
      } else if (response.data && response.data.statusCode && response.data.statusCode == "UnAuthorized") {
        toast.error("You do not have access permission");
      } else if (response.data && response.data.statusCode && response.data.statusCode == "NotFound") {
        toast.error("Your desired information was not found");
        navigate(`${process.env.PUBLIC_URL}/admin/Questions`);
      } else if (response.response && response.response.data.isSuccess == false) {
        toast.error(response.response.data.message);
      } else {
        toast.error("Error - 1");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Error - 0");
    }
  };

  const ResetForm = async () => {
    setobj({
      ...obj,
      text: "",
    });
    setSelectedRowID(0);
    navigate(`${process.env.PUBLIC_URL}/admin/Questions`);
  };

  return (
    <Fragment>
      <Form className="theme-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Container fluid={true}>
          <Breadcrumbs parent="Service Management" title="Question" Loading={Loading} />
          <Row>
            <Col md="12" className="project-list">
              <Card>
                <Row>
                  <Col md="12">
                    <Link className="btn btn-primary" style={{ color: "white" }} to={`${process.env.PUBLIC_URL}/Admin/Questions`}>
                      Questions
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">{"Title"}</Label> <label className="txt-danger Required">{"*"}</label>
                        <Input
                          disabled={adviceID ? true : false}
                          type="select"
                          name="titleCategoryID"
                          className="form-control digits"
                          value={obj.titleCategoryID}
                          onChange={(txt) => {
                            onChangeCategory(txt.target.value);
                          }}
                        >
                          <option key={0} value={0}>
                            ...
                          </option>
                          {ListTitle != null
                            ? ListTitle.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.title}
                                  </option>
                                );
                              })
                            : null}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label>{"Questions"}</Label>
                        <Editor
                          apiKey="97twnsawko365at3kw26izpuzdz92cln8uduxn75z1lt9zju"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          init={{
                            plugins:
                              "directionality  ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss",
                            toolbar:
                              "ltr rtl | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                            tinycomments_mode: "embedded",

                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                          }}
                          initialValue={obj.text}
                          onChange={onInputChange}
                          name="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {SelectedRowID == 0 ? (
                        <Button type="submit" className="btn btn-success btn-md">
                          {LoadingBtn ? (
                            <div className="loader-box2">
                              <Spinner attrSpinner={{ className: "loader-7" }} />
                            </div>
                          ) : (
                            "Add"
                          )}
                        </Button>
                      ) : (
                        <>
                          <Button type="submit" className="btn btn-success btn-md m-r-15">
                            {LoadingBtn ? (
                              <div className="loader-box2">
                                <Spinner attrSpinner={{ className: "loader-7" }} />
                              </div>
                            ) : (
                              "Save"
                            )}
                          </Button>
                          {LoadingBtn == false ? (
                            <Btn
                              attrBtn={{
                                color: "light",
                                type: "button",
                                onClick: () => ResetForm(),
                              }}
                            >
                              Cancel
                            </Btn>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Fragment>
  );
};

export default QuestionAdd;
